.wrapper {
  padding: 64px 0;

  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin: 0 auto;
  }

  .heroTitle,
  .heroDescription {
    color: var(--dark100);
    text-align: center;
  }

  .heroDescription {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 900px) {
    padding: 32px 16px;
    .heroDescription {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
