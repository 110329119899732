.container {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: flex-end;
}

.select {
  height: 44px;
  background: transparent;
  align-items: center;
  // border: 1px solid var(--Dark-60, rgba(31, 31, 31, 0.6));
  background: var(--Gray-2, #f2f2f2);

  :global(.ant-select-selector) {
    padding: 8px 4px !important;
  }

  :global(.ant-select-selection-item) {
    color: var(--dark100) !important;
  }
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--urbanist);
  font-weight: normal;
}

.icon {
  align-content: center;
  font-size: 20px;
  display: block;
}

p.text {
  color: var(--dark60);
  font-size: 14px;
  line-height: 20px;
}

.arrow {
  font-size: 16px;
  color: #000;
  transition: transform 0.25s;

  &.isOpen {
    transform: scale(1, -1);
  }
}

.check {
  font-size: 16px;
  color: #000;
}

.dropdown {
  :global(.ant-select-item-option-selected) {
    background: none !important;
  }
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    justify-content: space-between;
    background-color: var(--gray100);
    padding: 0 16px;
  }
}
