.wrapper {
  width: 100vw;
  padding-top: 64px;
}

.title {
  font-size: 56px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 1.68px;
  text-align: center;
  text-transform: uppercase;
}

.quote {
  max-width: 1030px;
  padding: 40px 42px;
  background-color: var(--gray200);
  margin: 48px auto 32px;
  display: flex;
  justify-content: center;
}

.quoteContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.quoteImg {
  width: 27px;
}

.quoteText {
  font-size: 18px;
  font-style: italic;
  line-height: 24px;
}

.row {
  display: flex;
  justify-content: center;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-width: 480px;
}

.img {
  margin-bottom: 40px;
  width: 100%;
  max-height: 330.96px;
  object-fit: contain;
}

.colTitle {
  font-size: 22px;
  font-weight: 800;
  line-height: 120%;
  text-align: center;
}

.text {
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.arrow {
  align-self: center;
  margin-bottom: 130px;
}

@media (max-width: 800px) {
  .wrapper {
    padding-top: 32px;
  }

  .title {
    font-size: 32px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 3px;
  }

  .quote {
    gap: 12px;
    margin: 24px 0 32px;
    padding: 18px 24px;
    border-left: 1px solid #000;
  }

  .quoteImg {
    width: 20px;
  }

  .img {
    padding: 0;
    height: fit-content;
    margin-bottom: 16px;
    max-width: calc(100vw - 160px);
  }

  .row {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .arrow {
    margin-bottom: 0;
    transform: rotate(90deg);
    width: 30px;
    height: 30px;
    margin-bottom: 12px;
  }

  .col {
    align-items: center;
    justify-content: center;
  }

  .colTitle {
    margin-bottom: 16px;
  }
}
