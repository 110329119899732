.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding-top: 80px;

  @media (max-width: 613px) {
    gap: 0;
    padding-top: 24px;
  }
}

.title {
  font-family: var(--urbanist);
  font-weight: 900;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: 1.44px;
  text-align: center;

  @media (max-width: 613px) {
    font-size: 28px;
    font-weight: 800;
    line-height: 120%;
  }
}

.image {
  height: auto;
  width: 100%;
  max-width: 768px;
  aspect-ratio: 768/438;

  @media (max-width: 613px) {
    order: -1;
  }
}

.lang {
  position: absolute;
  top: 32px;
  right: 40px;
  flex-direction: row !important;
  gap: 2px !important;

  span,
  i {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  i {
    font-size: 24px !important;
  }
}

@media (max-width: 613px) {
  .lang {
    position: relative;
    top: unset;
    right: unset;
    order: -1;
    margin-left: auto;
  }
}
