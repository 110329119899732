.wrapper {
  padding: 40px;
  margin: 80px 0 0;
  background: rgba(183, 213, 224, 0.25);
  display: flex;
  align-items: center;
  flex-direction: column;

  .primary {
    margin-top: 60px;
  }

  @media (max-width: 613px) {
    padding: 24px 16px;
    margin: 16px -16px 0;
    width: 100vw;

    .primary {
      margin-top: 0;
    }
  }
}

.content {
  display: flex;
  gap: 24px;

  @media (max-width: 613px) {
    flex-direction: column;
  }
}

.blockWhite,
.col {
  flex: 1 0 0;
}

.blockWhite {
  background: #fff;
  padding: 40px;
  max-width: 446px;

  @media (max-width: 613px) {
    max-width: unset;
    padding: 24px;
    margin-bottom: 24px;
  }
}

.title {
  font-family: var(--urbanist);
  font-size: 22px;
  font-weight: 800;
  line-height: 120%;
  margin: 12px 0 24px;
}

.boldOrange,
.bold {
  font-family: var(--urbanist);
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.boldOrange {
  color: var(--coral);
  margin-top: 24px;

  @media (max-width: 613px) {
    display: none;
  }
}

.logo {
  filter: invert(1);
  width: 74px;
  height: 47px;

  img {
    object-fit: contain;
  }

  @media (max-width: 613px) {
    display: none;
  }
}

.list {
  padding-left: 18px;

  @media (max-width: 613px) {
    margin-bottom: 16px;
  }
}

.listItem {
  font-family: var(--urbanist);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 6px;
}

.icon {
  font-size: 40px;
  color: var(--coral);
}

.text {
  font-family: var(--urbanist);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 613px) {
    display: none;
  }

  &.margin {
    margin-top: 16px;
  }
}

.btnWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
}

@media (max-width: 613px) {
  .btnWrapper {
    margin: 0 -16px;
    width: calc(100% + 32px);
    padding-bottom: 80px;
    background: rgba(183, 213, 224, 0.25);
  }
}
