.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding-top: 80px;

  @media (max-width: 613px) {
    gap: 0;
    padding-top: 24px;
  }
}

.title {
  font-family: var(--urbanist);
  font-size: 18px;
  font-style: normal;
  line-height: 26px;
  text-align: center;
  max-width: 743px;

  @media (max-width: 613px) {
    display: none;
  }
}

.image {
  height: auto;
  width: 100%;
  max-width: 329px;
  aspect-ratio: 329/241;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.bold {
  font-weight: 600;
}

@media (max-width: 613px) {
  .btn {
    margin-top: 24px;
  }
}
