.container {
  padding: 32px 40px 0;
  background: var(--light200);

  .title {
    font-size: 64px;
    font-weight: 250;
    line-height: 64px;
    letter-spacing: 1.92px;
    text-transform: capitalize;
  }

  .wrapper {
    display: flex;
    gap: 24px;
    height: 100%;
  }

  .rightBar {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscribeButton {
    display: none;
    padding: 16px;
    border-radius: 12px;
    background: var(--sky100);
    width: fit-content;
    cursor: pointer;
  }

  @media (max-width: 1536px) {
    padding: 32px 32px 0;
  }

  @media (max-width: 1280px) {
    padding: 32px 16px 0;
  }

  @media (max-width: 900px) {
    padding: 16px 16px 0;
    .wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
    }

    .rightBar {
      grid-area: 1;
      width: 100%;
    }

    .title {
      font-size: 32px;
    }

    .subscribeButton {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .subscribeButton :global(.font-avenir-regular) {
      font-size: 16px;
      color: #000;
      font-weight: 700;
    }

    .titleWrapper {
      margin-bottom: 16px;
    }
  }
}

.announcmentItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: min(500px, calc(100vh - 428px));
  overflow: auto;
}
