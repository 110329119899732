.pie {
  --color1: #a96500;
  --color2: #f29100;
  --color3: #d9d9d9;
  --colorbg: #fff;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-image: conic-gradient(
    var(--color1) var(--from),
    var(--color2) var(--from),
    var(--color2) var(--to),
    #d9d9d9 var(--to)
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.green {
    --color1: #019f3c;
    --color2: #016f2a;
    --colorbg: #e6f5ec;
    --color3: #e6f5ec;
  }

  &::after {
    content: '';
    background: var(--colorbg);
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.table {
  font-family: var(--avenir);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 585px;

  @media (max-width: 700px) {
    min-width: 100%;
  }
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr 1.1fr 1fr;
  gap: 8px;
  align-items: stretch;
  overflow: hidden;

  .cell {
    background: #f4f7fb;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;

    &:first-child {
      font-weight: 700;
    }

    &:last-of-type {
      gap: 12px;
      text-align: center;
      justify-content: center;

      .percentage {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
        width: auto;

        .percentageText {
          font-weight: 700;
          color: #f29100;

          &.green {
            color: #019f3c;
          }
        }
      }
    }

    @media (max-width: 900px) {
      padding: 8px;
    }
  }

  &:first-of-type {
    color: #019f3c;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    .titleCell {
      background: var(--light300);
      padding: 8px;
    }

    @media (max-width: 900px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .cell:last-of-type {
    background: #fff;
  }

  &:last-of-type {
    .cell {
      background: #e6f5ec !important;

      &:nth-of-type(2) {
        @media (max-width: 500px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.row {
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  min-width: 182px;
  max-width: 342px;

  @media (max-width: 900px) {
    max-width: 100%;
  }
}

.h2 {
  color: #019f3c;
  padding-bottom: 12px;
  border-bottom: 3px solid #019f3c;
  font-family: var(--argent);
  font-size: 22px;
  font-weight: 600 !important;
  line-height: 24px;

  @media (max-width: 500px) {
    font-size: 22px;
    line-height: 24px;
  }
}

.pStyle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 394px;

  span {
    color: #019f3c;
  }

  @media (max-width: 900px) {
    max-width: 100%;
  }
}
