.wrapper {
  padding: 16px;

  .emailInput {
    color: #000430;
    padding: 12px;
    height: 44px;
    border: 1px solid #d0d4dc;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 4px;

    &:is(
        :-webkit-autofill,
        :autofill,
        :-internal-autofill-selected,
        :-internal-autofill-previewed
      ) {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #000;
      color: #000;
      box-shadow: none;
      background: #fff !important;
      transition: box-shadow 10000s, background 10000s;
    }
  }

  .emailInput::placeholder {
    color: #445371;
  }

  .emailInput.error {
    border: 2px solid var(--flame200);
  }

  .subscribeButton {
    width: 100%;
  }

  .subscribeButton button {
    width: 100%;
    text-align: center;
    text-transform: none;
    font-weight: 700;
    border-radius: 8px;
  }

  .subscribeButton {
    &.sendBtn button {
      margin-top: 32px;
      background-color: #000430;
      color: #fff;
      border: none;
    }

    &.sendBtn:hover button {
      background: #0d1724;
      border: none;
    }

    &.sendBtn:disabled button {
      opacity: 0.5;
    }

    &.cancelBtn {
      margin-top: 16px;
      height: fit-content;
    }

    &.cancelBtn button {
      color: var(--flame200);
      border: none;
      height: fit-content;
      padding-bottom: 8px;
    }
  }

  .subscribeDescription {
    font-size: 14px;
    font-family: var(--avenir);
  }

  .error {
    font-size: 12px;
    color: var(--flame200);
  }
}
