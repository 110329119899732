@layer {
  .title {
    font-family: var(--merchant);
    font-weight: 200;
    line-height: 110%;
    text-transform: uppercase;

    &:is(h1) {
      font-size: 56px;
      letter-spacing: 1.68px;

      @media (max-width: 800px) {
        font-size: 32px;
        line-height: 120%;
        font-weight: 400;
        letter-spacing: 3px;
      }
    }

    &:is(h2) {
      font-size: 32px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 3px;

      @media (max-width: 800px) {
        font-size: 28px;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }
}
