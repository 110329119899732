@import '@/styles/functions.scss';

.wrapper {
  width: 100vw;
  height: 100lvh;
  position: fixed;
  top: 0;
  right: 100%;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    linear-gradient(0deg, rgba(31, 31, 31, 0) 38.97%, var(--dark100) 100%);
  backdrop-filter: blur(7px);
  z-index: 21;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--headerHeight);
  overflow-y: auto;

  &.active {
    right: 0;
  }
}

.menusWrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: spx(4) spx(3);

  .join-wait-list {
    margin-top: 40px;
    width: var(--sp25x);
  }

  :global(.burgerMenuLink),
  .burgerMenuLink {
    position: relative;
    padding: spx(1.5) 0;
    text-align: center;
    font-weight: 500;
    padding-left: 0;

    .text {
      font-size: 12px;
      font-weight: 450;
      line-height: 20px;
      letter-spacing: 0.96px;
      text-transform: uppercase;
      font-family: var(--avenir);
      color: #fff;
      text-transform: uppercase;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 6px;
      left: calc(50% - 3px);
      width: 6px;
      height: 6px;
      background-color: #cd7764;
      border-radius: 50%;
      opacity: 0;
      transition: opacity var(--trTime) ease;
    }

    &:global(.active) {
      font-weight: 700;

      &::before {
        opacity: 1;
      }
    }

    &.active {
      font-weight: 700;

      &::before {
        opacity: 1;
      }
    }
  }

  .joinWaitList {
    margin-top: spx(7);
    width: spx(25);
  }

  :global(.yn_button) {
    margin-top: 72px;
    padding-top: 0;
    text-transform: uppercase;
    padding-right: 10px;
  }
}
