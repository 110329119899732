.dropdown {
  width: 216px;
  padding: 8px;
  display: flex;
  gap: 8px;
  border: 1px solid #fff;
  color: #fff;
  align-items: center;
  height: 44px;
  border-radius: var(--border-radius);
  cursor: pointer;

  &.open {
    border-width: 2px;

    .dropdownArrow {
      transform: scale(1, -1);
    }
  }

  &.minified {
    width: fit-content;
    border: none;
    height: fit-content;
    flex-direction: column;
    gap: 0;
    padding: 10px 12px;

    .dropdownText {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2px;
      text-align: center;
      width: 100%;
    }

    .dropdownArrow {
      display: none;
    }

    &:global(.ant-dropdown-open) {
      .dropdownText {
        font-weight: 700;
      }
    }
  }
}

.dropdownArrow {
  transition: transform 0.2s ease;
}

.dropdownText {
  color: var(--color);
  font-family: var(--urbanist);
  font-size: 14px;
  line-height: 20px;
  margin-right: auto;
}

.dropdownIcon {
  color: var(--color);
}

.dropdownWrapper {
  width: 432px;
  border: 1px solid #fff;
  border-radius: var(--border-radius);

  :global(.ant-dropdown-menu) {
    border-radius: var(--border-radius) !important;
    background: var(--background) !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 352px;

    :global(.ant-dropdown-menu-item) {
      border-radius: 0 !important;
      padding: 0 8px !important;
      height: 44px !important;
      width: 50%;

      &:nth-of-type(-n + 8) {
        border-right: 1px solid rgba(255, 255, 255, 0.16);
      }

      span {
        color: var(--color);
        font-family: var(--urbanist);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .autoTranslationMark {
        overflow: hidden;
        color: var(--secondaryTextColor);
        font-family: var(--urbanist);
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.4px;
      }

      &:global(.ant-dropdown-menu-item-disabled) {
        padding: 8px 8px 4px !important;
        height: fit-content !important;

        span {
          font-size: 12px;
          color: var(--secondaryTextColor) !important;
          line-height: 16px;
        }

        &:hover {
          background-color: var(--background) !important;
        }
      }

      &:hover {
        background-color: var(--hoverBackground) !important;

        span {
          color: var(--hoverColor);
        }
      }
    }

    :global(.ant-dropdown-menu-item-divider) {
      background-color: var(--color);
      margin: 0;
    }
  }
}

.dropdownSelected {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 695px) {
  .dropdown {
    width: 100%;
  }

  .dropdownWrapper {
    width: 320px;

    &.isInFooter {
      width: calc(100vw - 32px);
      left: 16px !important;

      :global(.ant-dropdown-menu) {
        display: block;
        max-height: unset;

        :global(.ant-dropdown-menu-item) {
          width: 100%;
          border: none;
        }
      }
    }
  }
}
