.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid var(--dark60);

  &:global(.ant-dropdown-open) {
    border-width: 2px;

    .icon {
      transform: scale(1, -1);
    }
  }
}

.icon {
  font-size: 20px;

  transition: transform 0.25s ease;
}

.overlayWrapper {
  border: 1px solid var(--dark60) !important;

  :global(.ant-dropdown-menu) {
    border-radius: 0;
    padding: 0;
  }

  :global(.ant-dropdown-menu-title-content) {
    font-family: var(--urbanist);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
  }

  :global(.ant-dropdown-menu-item) {
    padding: 12px !important;
    border-radius: 0;
  }
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
