.wrapper {
  background: var(--gray200);
  min-height: 748px;
  padding: 128px 0;
  color: var(--dark100);

  .title {
    margin-bottom: 64px;
    text-align: center;
  }

  @media (max-width: 900px) {
    padding: 32px 0;

    > .container {
      padding-right: 16px;
      padding-left: 16px;
    }

    .title {
      margin-bottom: 24px;
    }
  }
}

.tabsCont {
  display: flex;
  gap: 24px;
  flex: 1 1;
  width: 100%;
  position: relative;

  .imageWrapper,
  .tabsWrapper {
    width: 100%;
    flex: 1 0 0;
  }

  .tabsWrapper {
    min-width: 467px;
    max-width: 851px;
  }

  .imageWrapper {
    width: 100%;
    max-width: 374px;
    height: 638px;
    flex-shrink: 3;
    position: relative;
  }

  .tabImage {
    position: absolute;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    aspect-ratio: 389/586;
    max-width: 374px;
    width: 100%;
    height: auto;

    img {
      object-fit: contain;
    }

    &.active {
      opacity: 1;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    position: relative;

    .tabsWrapper {
      min-width: unset;
      max-width: unset;
    }

    .tabImage {
      opacity: 1;
      height: 100%;
      width: auto;
      position: relative;
      aspect-ratio: 360/453;
      max-height: 453px;
      margin: 0 auto;
    }

    .imageWrapper {
      height: 100%;
      width: 100%;
      max-width: none;
    }
  }
}

.tabNav {
  display: flex;
  flex: 1 1;
  width: 100%;
  border-bottom: 2px solid var(--dark60);
  padding-bottom: 8px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 4px;
    bottom: -3px;
    left: var(--left);
    background: var(--coral);
    width: 25%;
    transition: left 0.8s ease-in-out;
  }

  .tabNavItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    color: var(--dark100);
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.8s ease-in-out, color 0.8s ease-in-out;

    &:hover {
      opacity: 0.6;
    }

    &.active {
      opacity: 1;
      color: var(--coral);
    }

    .text {
      font-family: var(--avenir);
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      color: var(--dark100);
      text-align: center;
      white-space: pre;
    }

    .icon {
      font-size: 32px;
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.tabContent {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 32px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    opacity: 0;
    width: calc(100% - 80px);
    top: 0;
    width: 100%;

    &.transition {
      transition: opacity 0.8s ease-in-out;
    }

    &.active {
      opacity: 1;
    }
  }

  .listHeader {
    display: none;
  }

  .listItem {
    display: flex;
    gap: 8px;
    align-items: center;

    .num {
      font-family: var(--avenir);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      width: 24px;
      display: inline-block;
      color: var(--coral);
      flex-shrink: 0;
    }
  }

  .text {
    font-family: var(--urbanist);
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    margin-top: -48px;
    flex-direction: column;
    padding: 0;
    background: none;
    height: auto;

    .list {
      opacity: 1;
      position: relative;
      width: 100%;
      max-height: calc(62px + 48px);
      overflow: hidden;
      transition: max-height 0.4s ease-in-out;
      background: none;
      top: unset;
      padding: unset;

      &.activeMobile {
        max-height: 1200px;
      }
    }

    .listHeader {
      display: flex;
      margin-top: 48px;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .mobileHeader {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .icon {
          font-size: 32px;
          color: var(--coral);
        }
      }

      .iconBtn {
        position: relative;
        width: 24px;

        .icon {
          position: absolute;
          font-size: 24px;
          color: var(--dark100);
          opacity: 0;
          transition: opacity 0.4s ease-in-out;
        }

        .close {
          opacity: 1;
          transform: rotate(45deg);
        }

        &.active {
          .icon {
            opacity: 1;
          }
          .close {
            opacity: 0;
          }
        }
      }

      .mobileTitle {
        font-size: 22px;
        font-weight: 800;
        line-height: 24px;
      }
    }
  }
}
