.wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: #fff;
  gap: 4px;
  position: relative;
  margin-right: 12px;
  margin-top: 4px !important;
  padding-top: 4px;
}

.text {
  font-family: var(--urbanist);
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 450;
}

.counter {
  font-family: var(--urbanist);
  font-size: 8px;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0.291px;
  text-align: center;
  width: 16px;
  padding: 2.5px 0;
  background: var(--coral);
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 0;
  margin-left: 16px;
}

@media (max-width: 1199px) {
  .wrapper {
    margin-top: 80px !important;
    padding-top: 0;
    flex-direction: row;
  }

  .text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.96px;
  }

  .counter {
    right: 0;
    margin-left: unset;
  }
}
