.row {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;

  .listItemTitle {
    font-weight: 600;
    font-size: 22px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  width: 100%;

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #019f3c;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .btn {
    margin: 20px auto 16px;
  }

  .transferText {
    text-align: center;
    margin: 0 auto;
    max-width: 518px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 500px) {
    .description {
      font-size: 14px;
      line-height: 20px;
    }

    .btn {
      margin: 20px auto 12px;
    }
  }
}

.checkbox {
  position: relative;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  border: 2px solid #019f3c;
  flex-shrink: 0;
}

.checkIcon {
  position: absolute;
  font-size: 23px;
  bottom: 1px;
  left: -1px;
  flex-shrink: 0;
}

.list {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  padding: 32px 0 16px;

  @media (max-width: 500px) {
    padding: 0;
  }
}

.listItem {
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: #016f2a;
  align-items: center;

  .icon {
    font-size: 24px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    .icon {
      font-size: 20px;
    }
  }
}

.blockGrey {
  padding: 32px;
  border-radius: 12px;
  background: #f4f7fb;
  display: flex;
  flex-direction: column;

  .listItemDescription {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 500px) {
    padding: 18px 12px;
    margin-bottom: 40px;

    .listItemDescription {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.h2 {
  color: #019f3c;
  padding-bottom: 12px;
  border-bottom: 3px solid #019f3c;
  font-size: 22px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;

  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 20px;
  }
}
