@import '@/styles/functions.scss';

.warpper {
  --inputPadBottom: #{spx(2.5)};
  --textareaHeight: #{spx(13)};

  input,
  textarea {
    padding: 0;
    padding-bottom: var(--inputPadBottom);
    border-radius: 0;
    background-color: transparent;
    border: unset;
    border-bottom: 1px solid var(--white1000);
    width: 100%;
    font-size: var(--p5);
    font-family: var(--avenir);
    line-height: var(--lineHeightL);
    font-weight: 400;
    color: var(--white1000);
    transition: unset;

    &::placeholder {
      font-size: var(--p5);
      line-height: var(--lineHeightL);
      font-family: var(--avenir);
      font-weight: 400;
      color: var(--white1000);
    }
  }

  textarea {
    height: var(--textareaHeight);
    min-height: var(--textareaHeight);
    max-height: var(--textareaHeight);
  }

  :global(.ant-input-suffix),
  :global(.ant-input-textarea-suffix) {
    display: none;
  }
}
