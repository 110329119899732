.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;

  .bold {
    font-weight: 800;
    margin-bottom: 4px;
  }

  p {
    font-size: 22px;
    line-height: 120%;
  }

  > div {
    p:last-of-type,
    a:last-of-type {
      font-size: 16px;
      line-height: 24px;
    }
    a {
      text-decoration-line: underline;
    }
  }

  @media (max-width: 800px) {
    .bold {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    }
  }
}
