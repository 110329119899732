.wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: url('/images/spouse/header.webp?id=1') lightgray 60% / cover
    no-repeat;
  background-color: #e6eeff;
  gap: 34px;
  padding: 96px 16px 178px;
  margin-bottom: -120px;
  position: relative;

  .sidePeng {
    margin-top: 150px;
  }

  :global(.image-cont) img {
    object-fit: contain;
  }

  .mobileBg {
    display: none;
  }

  @media (max-width: 860px) {
    padding-bottom: 47px;
    margin-bottom: 32px;
    background: url('/images/spouse/header.webp?id=1') lightgray 50% 100% / auto
      auto no-repeat;
    background-color: #e6eeff;

    .sidePeng {
      display: none;
    }
  }

  @media (max-width: 500px) {
    margin-top: 0;
    padding-top: 16px;
    padding-bottom: 0;
    margin-bottom: 24px;
    background: var(--sky100);

    br {
      display: none;
    }
  }

  @media (max-width: 1360px) {
    .sidePeng {
      margin-top: 100px;
    }
  }

  @media (max-width: 1000px) {
    .sidePeng {
      margin-top: 200px;
    }
  }

  @media (min-width: 2000px) {
    .sidePeng {
      margin-top: 275px;
    }
  }
}

.content {
  text-align: center;
  width: 588px;
  flex-shrink: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .tabletPengs {
    display: none;
    justify-content: space-between;
    width: 484px;
    align-items: center;
    margin: 10px auto 44px;

    :global(.image-cont) {
      height: 300px !important;
      flex-basis: 25%;
    }
  }

  @media (max-width: 860px) {
    width: 100%;

    .tabletPengs {
      display: flex;
    }
  }

  @media (max-width: 500px) {
    .tabletPengs {
      display: none;
    }

    .mobileBg {
      display: block;
      width: calc(100% + 32px);
      height: auto;
      aspect-ratio: 404/269;
      margin-left: -16px;

      img {
        display: block;
        object-fit: cover;
        mix-blend-mode: multiply;
      }
    }
  }
}

.blur {
  position: absolute;
  width: 100%;
  height: 332px;
  bottom: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;

  @media (max-width: 860px) {
    display: none;
  }
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  @media (max-width: 860px) {
    order: 1;
  }

  @media (max-width: 500px) {
    background: #fff;
    margin-left: -16px;
    width: 100vw;
    border-radius: 20px;
    padding: 24px;
    margin-top: -20px;
    z-index: 1;
    box-shadow: 0px 0px 8px 0px rgba(68, 83, 113, 0.1),
      0px 2px 4px 0px rgba(68, 83, 113, 0.05);
  }
}

.question {
  display: flex;
  padding: 11px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #fff;
  width: 100%;
  justify-content: center;

  .icon {
    font-size: 24px;
    color: var(--sky300);
  }

  :global(.comp-text) {
    font-weight: 700;
    line-height: 20px;
    font-size: 14px;
    font-family: var(--avenir);
  }

  @media (max-width: 500px) {
    width: 100%;
    text-align: left;
    background: var(--sky100);
  }
}

.h1 {
  font-family: var(--argent);
  color: var(--sky300);
  text-align: center;
  font-size: 42px;
  font-weight: 400;
  line-height: 44px;
  margin-bottom: 32px;

  @media (max-width: 500px) {
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
  }
}

.bold {
  color: #000430;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #000430;
  margin-top: 16px;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}

.light {
  color: #000430;
  font-size: 18px;
  line-height: 26px;
  margin-top: 16px;
  margin-bottom: 34px;

  @media (max-width: 500px) {
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.lang {
  position: absolute;
  top: 32px;
  right: 40px;
  flex-direction: row !important;
  gap: 2px !important;
  margin-bottom: 16px;

  span,
  i {
    color: #445371 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  i {
    font-size: 24px !important;
  }
}

.logo {
  filter: invert(1);
  margin: 0 auto 24px;
}

@media (max-width: 500px) {
  .topRow {
    order: -1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .lang {
    position: relative;
    top: unset;
    right: unset;
    margin-bottom: 0;
  }

  .logo {
    margin: 0;
    max-width: 74px;
    max-height: 40px;
  }
}
