@import '@/styles/functions.scss';

.wrapper {
  --popupMaxHeight: 100vh;
  --popupContentMaxHeight: 100%;
  --popupMaxWidth: #{spx(139)};
  --popupPadTop: #{spx(19)};
  --popupPadBot: #{spx(13)};
  --popupPadRL: #{spx(13)};
  --closeSize: #{spx(9)};
  --closePosTopSize: #{spx(6)};
  --closePosRightSize: #{spx(6)};
  --popupBorderRadius: #{spx(4)};

  position: fixed;
  width: 100vw;
  inset: 0;
  z-index: 50;

  color: #fff;

  .description {
    white-space: pre-line;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    margin-bottom: 24px;
  }

  .popupBackground {
    background-color: #000;
    opacity: 40%;
    pointer-events: all;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.hide {
      opacity: 0;
    }
  }

  .popupContentContainer {
    max-width: var(--popupMaxWidth);
    max-height: var(--popupMaxHeight);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: all;
    overflow: scroll;
    margin: auto;
    justify-content: center;

    &::-webkit-scrollbar {
      display: none;
    }

    .popupContentWrap {
      position: relative;
      height: fit-content;
      box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.1);
      opacity: 1;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-radius: var(--popupBorderRadius);
      padding: 0;
      background: #fff;
      color: #000430;
      width: fit-content;
      transition: margin-bottom 0.25s;
      margin-block-end: max(env(keyboard-inset-height, 24px), 24px);
      max-height: calc(100% - 32px);

      &.bottom {
        align-self: flex-end;
      }

      &.fixKb {
        margin-bottom: 250px;
      }

      &.fixed {
        position: fixed;
        left: min(var(--posX), calc(100% - 300px));
        top: clamp(100px, var(--posY), 100vh);
        transform: translateX(-50%) translateY(calc(-100% - 24px));
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .closeWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: var(--closeSize);
        height: var(--closeSize);
        top: var(--closePosTopSize);
        right: var(--closePosRightSize);
        z-index: 2;
        border: 0 solid var(--white1000);
        border-radius: #{spx(1)};
        cursor: pointer;

        i {
          font-size: var(--p3);
          color: var(--white1000);
        }
      }
    }
  }

  .popupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #d0d4dc;
  }

  .popupHeader .text {
    font-size: 22px;
    font-weight: 800;
    line-height: 120%;
    color: #001731;
    font-family: var(--urbanist);
  }

  .popupHeader .icon {
    border-radius: 50%;
    background: var(--light200);
    padding: 4px;
    cursor: pointer;
  }

  //! 1920
  @media only screen and (max-width: 1920px) {
    --popupMaxWidth: #{spx(103)};
    --popupPadTop: #{spx(14)};
    --popupPadBot: #{spx(10)};
    --popupPadRL: #{spx(10)};
    --closeSize: #{spx(7)};
    --closePosTopSize: #{spx(5)};
    --closePosRightSize: #{spx(5)};
    --popupBorderRadius: #{spx(3)};
  }

  //! 1536
  @media only screen and (max-width: 1536px) {
    --popupMaxWidth: #{spx(103)};
    --popupPadTop: #{spx(14)};
    --popupPadBot: #{spx(10)};
    --popupPadRL: #{spx(10)};
    --closeSize: #{spx(7)};
    --closePosTopSize: #{spx(5)};
    --closePosRightSize: #{spx(5)};
    --popupBorderRadius: #{spx(3)};
  }

  //! 1366
  @media only screen and (max-width: 1366px) {
    --popupMaxWidth: #{spx(103)};
    --popupPadTop: #{spx(12)};
    --popupPadBot: #{spx(8)};
    --popupPadRL: #{spx(8)};
    --closeSize: #{spx(6)};
    --closePosTopSize: #{spx(4)};
    --closePosRightSize: #{spx(4)};
    --popupBorderRadius: #{spx(3)};
  }

  //! 1280
  @media only screen and (max-width: 1280px) {
    --popupMaxWidth: #{spx(83)};
    --popupPadTop: #{spx(11)};
    --popupPadBot: #{spx(7)};
    --popupPadRL: #{spx(7)};
    --closeSize: #{spx(6)};
    --closePosTopSize: #{spx(3)};
    --closePosRightSize: #{spx(3)};
    --popupBorderRadius: #{spx(3)};
  }

  //! 768
  @media only screen and (max-width: 768px) {
    --popupMaxWidth: #{spx(83)};
    --popupPadTop: #{spx(11)};
    --popupPadBot: #{spx(6)};
    --popupPadRL: #{spx(6)};
    --closeSize: #{spx(6)};
    --closePosTopSize: #{spx(3)};
    --closePosRightSize: #{spx(3)};
    --popupBorderRadius: #{spx(3)};
  }

  //! Mobile
  @media only screen and (max-width: 500px) {
    --popupMaxWidth: calc(100% - #{spx(4)});
    --popupPadTop: #{spx(9)};
    --popupPadBot: #{spx(3)};
    --popupPadRL: #{spx(3)};
    --closeSize: #{spx(5)};
    --closePosTopSize: #{spx(2)};
    --closePosRightSize: #{spx(2)};
    --popupBorderRadius: #{spx(2)};

    .popupContentContainer .popupContentWrap.fixed {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
    }

    .popupBackground.hide {
      opacity: 0.4;
    }
  }
}
