.link {
  color: var(--sky200);
  font-weight: 700;
  font-size: 14px;
  font-family: var(--avenir);
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}

.container {
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 800px) {
    margin: 0 16px 0;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 6px;

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--avenir);
  }

  @media (max-width: 800px) {
    display: inline;
    text-align: center;

    .emoji {
      display: inline;
    }

    .text {
      display: inline;
      font-size: 18px;
      line-height: 26px;
    }
  }
}
