.wrapper {
  background: #445371;
  padding: 32px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    font-family: var(--avenir);
  }

  &.grey {
    background: var(--dark60);
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (max-width: 800px) {
    padding: 24px;

    &.grey {
      padding: 24px;
      .lang {
        display: none;
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  a {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.75;
    }
  }
}
