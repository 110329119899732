.row {
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  min-width: 182px;
  max-width: 342px;

  @media (max-width: 900px) {
    max-width: 100%;
  }
}

.h2 {
  color: #de0016;
  padding-bottom: 12px;
  border-bottom: 3px solid #d91528;
  font-size: 22px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;

  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.prescription {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 500px) {
    font-weight: 400;
  }
}

.listItem {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 4px;
  background: #fbe8ea;
  border-radius: 12px;
  flex: 1 0 0;

  @media (max-width: 500px) {
    flex-basis: calc(50% - 16px);
  }
}

.listItemTitle {
  color: #d91528;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  font-family: var(--argent);

  @media (max-width: 860px) {
    font-size: 22px;
    line-height: 24px;
  }
}

.listItemText {
  color: #980f1c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--avenir);
}

.list {
  display: flex;
  gap: 16px;
  min-width: 558px;
  align-items: stretch;

  @media (max-width: 600px) {
    min-width: unset;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
}

.checkboxes {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  color: #445371;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.checkbox {
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
  white-space: nowrap;

  .border {
    border: 2px solid #d0d4dc;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  @media (max-width: 860px) {
    gap: 4px;

    .checkboxLabel {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.checked {
  position: absolute;
  left: -3px;
  top: -3px;
  font-size: 20px;
  color: #000430;

  @media (max-width: 860px) {
    font-size: 16px;
    left: -1px;
    top: 1px;
  }
}
