.wrapper {
  width: 100vw;
}

.title {
  font-size: 32px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 3px;
  text-align: center;
}

.chart {
  margin-top: 42px;
  margin-bottom: 32px;
}

.row {
  display: flex;
}

.row.header {
  position: sticky;
  top: var(--headerHeight);
  background-color: #fff;

  .icon {
    font-size: 32px;
    color: var(--dark60);
    width: 32px;
    height: 32px;
  }
}

.cell {
  flex: 1 0 0;
  text-align: center;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #e3e3e3;
  margin-left: -1px;
  margin-bottom: -1px;
  flex-basis: 25%;

  &:first-of-type {
    font-weight: 700;
    border-left: none;
    border-top: none;
    text-align: left;
    align-items: flex-start;
    color: #062c43;
  }

  .icon {
    border-radius: 50%;
    font-size: 11px;
    color: #fff;
    padding: 2px;
    flex-shrink: 0;
    display: block;
  }
}

.logo {
  filter: invert(1);
  width: 74px;
  height: 40px;
}

.text {
  font-size: 14px;
  line-height: 20px;
}

.check {
  background-color: #019f3c;
}

.cross {
  background-color: #de0016;
}

.btn {
  margin: 0 auto;
}

.share {
  margin-left: auto;
  margin-bottom: 48px;

  button {
    padding: 0;
    height: fit-content;
  }
}

.shareText {
  color: var(--dark100);
  text-transform: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-family: var(--avenir);
  color: var(--dark100);
}

.shareIcon {
  color: var(--dark100);
  font-size: 20px;
  margin-right: 4px;
  &::before {
    color: var(--dark100);
  }
}

@media (max-width: 800px) {
  .wrapper {
    background: var(--gray200);
    padding: 48px 0;
  }

  .title {
    font-size: 28px;
    line-height: 120%;
  }

  .text {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  .row.header {
    background: var(--gray200);

    .cell {
      padding: 24px 4px;
      &:last-of-type {
        justify-content: center;
      }
    }

    .icon {
      display: none;
    }

    .text {
      font-size: 11px;
      max-width: 224px;

      b {
        font-size: 12px;
      }
    }
  }

  .cell {
    padding: 24px 8px;
    justify-content: flex-start;
    &:first-of-type {
      padding: 24px 4px 24px 0;
      justify-content: center;
      .text {
        text-align: left;
        font-size: 12px;
        letter-spacing: normal;
      }
    }
  }

  .logo {
    width: 100%;
  }

  .chart {
    margin-bottom: 16px;
  }
}
