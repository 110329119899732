.wrapper {
  padding-bottom: 128px;

  .container {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin-bottom: 24px;
  }

  .text {
    font-family: var(--urbanist);
    color: var(--dark60);
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    padding: 0 16px 64px 16px;

    .title,
    .text {
      text-align: center;
    }

    .container {
      padding: 0;
    }
  }
}
