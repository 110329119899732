.container {
  width: 100%;
  margin: 0 auto;

  &:not(.full) {
    padding-left: max(48px, calc((100vw - var(--maxWidth)) / 2));
    padding-right: max(48px, calc((100vw - var(--maxWidth)) / 2));

    @media (max-width: 800px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &.sectionDistance {
    margin-bottom: var(--sectionDistance);
  }

  &.full {
    padding-left: 0;
    padding-right: 0;
  }

  .row {
    display: flex;
  }
}
