.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  height: 100%;
  aspect-ratio: 1052 / 640;
}

.videowrap {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.img {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  width: auto;
}

@media (max-aspect-ratio: 1.65 / 1), (max-width: 195vh), (max-height: 756px) {
  .container {
    height: auto;
    width: 100%;
  }
}

@media (max-height: 570px), (max-width: 900px) {
  .container {
    padding-top: 0;
    flex-direction: column-reverse;
    aspect-ratio: unset;
  }

  .videowrap {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 600px) {
  .container {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    gap: 0;
  }
}
