.container {
  background: #fff;
  color: var(--dark100);
  min-height: calc(100vh - 142px);

  &.padding {
    padding-bottom: 40px;
  }

  @media (max-width: 800px) {
    min-height: calc(100vh - 108px);
  }
}

.fileTitle {
  font-family: var(--argent);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.fileDescription {
  font-family: var(--avenir);
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

.file {
  display: block;
  outline: 2px solid rgba(0, 4, 48, 0.16);
  max-width: 437px;
  cursor: pointer;
  div {
    padding: 24px;
  }

  @media (min-width: 800px) {
    &:hover {
      outline: 2px solid #445371;
    }
  }

  img {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.files {
  max-width: 1360px;
  margin: 0 auto;
  padding: 40px 16px;
  display: flex;
  gap: 21px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 24px 12px;
    align-items: center;
  }
}

.header {
  background: var(--sky100);
  padding: 40px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    font-family: var(--avenir);
    max-width: 1132px;
    margin: 0 auto;
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    padding: 24px 16px;
    p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 0;
    }
  }
}

.h1 {
  font-size: 48px;
  font-weight: 400;
  line-height: 52px;
  font-family: var(--argent);
  color: var(--sky300);

  @media (max-width: 800px) {
    font-size: 32px;
    line-height: 44px;
  }
}

.buttonLink,
.button {
  height: 44px;
  padding: 12px 16px;
  border-radius: 8px;
  background: #000430;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  font-family: var(--avenir);
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}

.buttonLink {
  background: none;
  padding: 0;
  color: var(--sky200);
  display: flex;
  gap: 4px;
  display: flex;
  align-items: center;

  .icon {
    font-size: 20px;
  }
}
