.wraper {
  h2 {
    text-align: center;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.charts {
  display: flex;
  gap: 24px;
  align-items: baseline;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 64px;
  }
}

.chart {
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  .percentage {
    font-family: var(--merchant);
    font-size: 28px;
    font-weight: 400;
    line-height: 120%;
    color: #242423;
  }

  p {
    font-family: var(--avenir);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    b {
      font-weight: 700;
    }
  }
}

.reports {
  display: flex;
  gap: 24px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 40px;
  }
}

.report {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: var(--gray200);
  padding: 24px;
  flex: 1 1;
  justify-content: center;
  align-items: center;

  > p {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    &:last-of-type {
      color: var(--dark60);
      margin-top: 2px;
      margin-bottom: 12px;
    }
  }
  .icon {
    font-size: 24px;
    color: var(--coral);
    margin-bottom: 8px;
  }

  @media (max-width: 800px) {
    padding: 16px;

    .btn-text {
      width: 100%;

      a,
      button {
        width: 100%;
      }
    }
  }
}
