@import '@/styles/functions.scss';
@layer {
  .wrapper {
    --defaultBtnPadLR: 16px;

    --iconBtnSize: #{spx(9)};

    &.destruction {
      height: fit-content;
      a,
      button {
        color: var(--flame200);
        border: none;
        height: fit-content;
        padding-bottom: 8px;
        background: none;
      }
    }

    &.territory {
      height: fit-content;
      a,
      button {
        color: var(--dark300);
        border: none;
        height: fit-content;
        padding-bottom: 8px;
        background: none;
      }

      &:hover {
        a,
        button {
          &:not([disabled]) {
            opacity: 0.75;
          }
        }
      }
    }

    &.primary {
      color: var(--white1000);

      a,
      button {
        background-color: var(--dark100);
        color: var(--white1000);
        border: 1px solid var(--dark100);
        transition: var(--trTime);
        border-radius: 0;

        &:disabled {
          opacity: 0.5;
        }
      }

      &:hover {
        a,
        button {
          &:not([disabled]) {
            background: #393939;
            color: var(--white1000);
            border: 1px solid #393939;
          }
        }
      }
    }

    &.secondary {
      background: red;
      color: var(--dark100);
      a,
      button {
        background-color: var(--gray100);
        color: var(--dark100);
        border: 1px solid var(--gray100);

        &:disabled {
          opacity: 0.5;
          color: var(--dark100);
        }
      }

      @media (hover: hover) {
        &:hover {
          a,
          button {
            &:not([disabled]) {
              color: var(--dark60) !important;
            }
          }
        }
      }
    }

    &.outline {
      a,
      button {
        background: #fff;
        color: var(--dark100);
        border: 1px solid var(--dark100);
        transition: var(--trTime);

        &:disabled {
          opacity: 0.5;
        }
      }

      @media (hover: hover) {
        &:hover {
          color: var(--white1000);
          a,
          button {
            &:not([disabled]) {
              background: #393939;
              color: var(--white1000);
              border: 1px solid #393939;
            }
          }
        }
      }
    }

    &.btnText {
      width: fit-content;

      a,
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: var(--avenir);
        font-weight: 450;
        font-size: 14px;
        line-height: var(--lineHeightL);
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        isolation: isolate;
        height: 44px;
        padding: 0 var(--defaultBtnPadLR);
      }

      &.btnLink {
        --icon-color: #fff;
        a {
          gap: 8px;
        }

        &.secondary {
          --icon-color: var(--dark100);
          &:hover {
            --icon-color: var(--dark60);
          }
        }

        &:hover .icon {
          transform: rotate(-45deg) translate(4px);
        }

        .icon::before {
          color: var(--icon-color) !important;
          font-size: 16px !important;
        }

        .icon {
          width: 20px;
          height: 20px;
          transform: rotate(-45deg);
          transition: transform var(--trTime) ease-out;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    button {
      background-color: inherit;
    }

    /* //! GLOBAL DISABLED */
    &.disabled {
      pointer-events: none;

      a,
      button {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
