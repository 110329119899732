.wrapper {
  padding-bottom: 128px;
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  white-space: pre-wrap;
  margin-bottom: 8px;
}

.bold {
  font-family: var(--urbanist);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.italic {
  font-style: italic;
}

.header {
  font-family: var(--urbanist);
  font-size: 22px;
  font-weight: 800;
  line-height: 120%;
  margin: 40px 0 8px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 800px) {
    margin: 32px 0 8px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.subHeader {
  font-family: var(--urbanist);
  font-size: 22px;
  font-weight: 800;
  line-height: 26px;
  margin-bottom: 8px;
  margin-top: 40px;
  letter-spacing: normal;
}

.h3 {
  font-family: var(--urbanist);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 26px;
  margin-bottom: 8px;
  letter-spacing: normal;

  &.light {
    font-weight: 400;
  }
  &.italic {
    font-style: italic;
  }
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  & > * {
    margin: 0 0 8px 0 !important;
    position: relative;
    padding-left: 20px;

    &::before {
      position: absolute;
      content: '';
      left: 7px;
      top: 12px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #000;
    }
  }
}

.listNumeric {
  counter-reset: line-number;
  & > * {
    counter-increment: line-number;
    &::before {
      content: counter(line-number) '.';
      background: none;
      width: 16px;
      height: auto;
      top: 0;
      left: 0;
      text-align: right;
    }
  }
}

.link {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-weight: 700;
  text-decoration: underline;
  font-family: var(--avenir);
  color: var(--dark300);
}

.inlineDiv {
  display: block;
  margin-bottom: 8px;

  & > * {
    display: inline;
    white-space: pre-wrap;
    margin-bottom: 0;
  }
}

.image {
  width: 100%;
  margin-bottom: 32px;
  display: block;
}
