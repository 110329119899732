.wrapper {
  .container {
    padding-left: max(48px, calc((100vw - 1200px) / 2)) !important;
    padding-right: max(48px, calc((100vw - 1200px) / 2)) !important;

    @media (max-width: 613px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
}
