.wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-radius: 12px;
  scroll-margin-top: 100px;

  .link {
    color: var(--sky200);

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .postDate {
    color: rgba(13, 23, 36, 0.4);
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 16px;
    font-family: var(--argent);
  }

  .postTitle {
    font-family: var(--argent);
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    border-bottom: 1px solid #e6e6ea;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .postLogo {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  .postContent {
    position: relative;
    display: flex;
    gap: 8px;
    flex: 1 1;
    white-space: pre-wrap;
  }

  .postContent::before {
    content: '';
    position: absolute;
    height: calc(100% - 56px);
    width: 1px;
    background: #e6e6ea;
    left: 18px;
    top: 38px;
  }

  .postContentText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1;
    transition: max-height 1s;
    max-height: var(--max-height);
  }

  .postUsername {
    font-family: var(--avenir);
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
  }

  .postText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-bottom: 16px;
    transition: all 0.5s;
    color: var(--dark100);
    -webkit-line-clamp: 100;
    font-family: var(--avenir);
  }

  .postFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-top: 20px;
    border-top: 1px solid #e6e6ea;
    gap: 12px;
  }

  .postExpandBtn {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
  }

  .postExpandText {
    font-size: 15px;
  }

  .postButtons {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    justify-content: flex-end;
    padding: 8px 0 24px;
  }

  .icon::before {
    color: #445371;
  }

  .icon {
    font-size: 20px;
  }

  .icon .postExpandBtn {
    transition: color 0.25s, opacity 0.25s;
  }

  .icon:hover,
  .postExpandBtn:hover {
    opacity: 0.75;
  }

  .icon:active,
  .active.active:before,
  .postExpandBtn:active,
  .icon:active::before {
    opacity: 1;
    color: var(--sky200);
  }

  .postIconExpand {
    transition: transform 0.25s;
  }

  .postIconExpand.open {
    transform: rotate(-180deg);
  }

  .shareButton.active::before {
    color: var(--sky200);
  }

  .linkWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 340px;

    .icon {
      padding: 12px;
      font-size: 20px;
      border-radius: 8px;
      border: 1px solid #d0d4dc;
      transform: rotate(90deg);

      &:before {
        color: #445371;
      }
    }

    .linkTextSmall {
      font-family: var(--avenir);
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #445371;
    }

    .linkTextTitle {
      font-family: var(--avenir);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &:last-of-type {
      text-align: right;
      flex-direction: row-reverse;
      .icon {
        transform: rotate(-90deg);
      }
    }
  }

  @media (max-width: 520px) {
    position: relative;
    .postFooter {
      justify-content: flex-start;
      gap: 16px;
      padding-top: 24px;
      margin-top: 8px;
    }

    .linkWrapper {
      width: 46px;
    }

    .linkTextSmall,
    .linkTextTitle {
      display: none;
    }

    .postButtons {
      position: absolute;
      bottom: 33px;
      right: 16px;
      padding: 0;
    }
  }
}

.listWrapper {
  ul,
  ol {
    padding-inline-start: var(--sp3x);
  }
}
