.wrapper {
  :global(.ant-form-item-has-error) {
    .ant-form-item-explain {
      margin-top: 4px;

      .ant-form-item-explain-error {
        color: var(--errorColor);
        font-size: var(--p7);
        font-family: var(--avenir);
        font-weight: 400;
        line-height: var(--lineHeightL);
      }
    }

    input,
    textarea,
    label {
      color: var(--errorColor) !important;
    }

    input,
    textarea {
      border: 2px solid var(--errorColor);

      &::placeholder {
        color: var(--errorColor) !important;
      }
    }
  }

  input,
  textarea {
    border: 1px solid var(--dark60);
  }
}
