.wrapper {
  padding: 40px max(48px, calc((100vw - 1200px) / 2)) 128px;
  background: var(--gray200);
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;

  &.fullWidth {
    padding: 0 0 128px;
  }

  @media (max-width: 613px) {
    max-height: unset;
    min-height: 580px;
    padding: 16px;

    &.fullHeight {
      min-height: 100vh;
    }

    &.fullWidth {
      padding: 0;
    }
  }
}
