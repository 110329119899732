.wrapper {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 32px;

    p {
      margin-bottom: 12px;
      max-width: 1130px;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      color: var(--dark60);
    }

    @media (max-width: 800px) {
      gap: 16px;

      p {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
