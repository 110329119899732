.wrapper {
  width: fit-content;
  padding: 16px;
  border-radius: 8px;

  p {
    font-family: var(--urbanist);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-line;
  }

  .territory {
    width: 100%;
  }

  .territory button {
    margin-top: 32px;
    padding: 0;
    width: 100%;
    height: auto;

    font-family: var(--avenir);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
  }
}
