.wrapper {
  font-family: var(--caveat);
  font-size: 22px;
  line-height: 26px;
  max-width: 740px;
  display: block;
  margin: 0 auto 128px;

  span {
    font-weight: 700;
  }

  @media (max-width: 613px) {
    display: none;
  }
}
