.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding-bottom: 128px;
  margin-top: 64px;

  @media (max-width: 613px) {
    margin-top: 12px;
    padding-bottom: 24px;
    gap: 24px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;

  @media (max-width: 613px) {
    gap: 24px 16px;
    grid-template-columns: 1fr 1fr;
  }
}

.note,
.percentage,
.gridItemText,
.description {
  font-family: var(--urbanist);
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;

  @media (max-width: 613px) {
    font-weight: 400;
  }
}

.gridItem {
  background: rgba(183, 213, 224, 0.25);
  padding: 24px;

  @media (max-width: 613px) {
    padding: 16px;
  }
}

.icon {
  color: var(--coral);
  font-size: 32px;

  @media (max-width: 613px) {
    display: none;
  }
}

.note {
  color: var(--dark60);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  width: 100%;
  margin-top: 24px;

  @media (max-width: 613px) {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.percentage {
  text-align: left;
  margin: 12px 0 16px;
  font-size: 22px;
  font-weight: 800;
  line-height: 120%;

  @media (max-width: 613px) {
    color: var(--coral);
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 8px 0;
  }
}

.gridItemText {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.btn {
  display: none;

  @media (max-width: 613px) {
    font-family: var(--urbanist);
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    .icon {
      transform: rotate(90deg);
      font-size: 20px;

      &::before {
        color: var(--dark100);
      }
    }
  }
}
