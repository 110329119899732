.stepNumber {
  font-family: var(--argent);
  font-weight: 600;
  color: var(--sky200);
  font-size: 18px;
  flex-shrink: 0;
  width: 18px;
  text-align: center;
}

.row {
  display: flex;
  gap: 8px;
  align-items: center;

  .listTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .description {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--avenir);
  }

  @media (max-width: 500px) {
    .description {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.list {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listItemContent {
  display: flex;
  flex-direction: column;

  .itemTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }
}

.listItem {
  display: flex;
  gap: 10px;
  font-size: 16px;
  align-items: flex-start;
  font-family: var(--avenir);
  line-height: 24px;
}

.blockGrey {
  padding: 24px;
  border-radius: 12px;
  background: #f4f7fb;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .blockTitle,
  .icon:before {
    color: var(--sky200);
  }

  .icon {
    font-size: 24px;
  }

  @media (max-width: 500px) {
    padding: 16px;
  }
}

.blockGreen {
  padding: 29px 37px;
  border-radius: 20px;
  background: #f2fdeb;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #016f2a;

  .blockTitle {
    padding-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 3px solid #019f3c;
  }

  .icon {
    font-size: 24px;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #000430;
  }

  .content {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 800px) {
    padding: 16px;
  }
}

.h2 {
  padding-bottom: 12px;
  border-bottom: 3px solid #d0d4dc;
  font-size: 22px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;

  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 20px;
  }
}
