.wrapper {
  width: 100%;
  max-width: 668px;
  flex-shrink: 0;

  > div {
    height: 100%;
  }

  @media (max-width: 1200px) {
    max-width: 550px;
  }

  @media (max-width: 1000px) {
    max-width: 450px;
  }

  @media (max-width: 800px) {
    max-width: unset;
    margin: 0 -16px;
    width: 100vw;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
  background: var(--gray200);
  height: 100%;
  width: 100%;
  position: relative;

  .titleBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  h3 {
    font-family: var(--urbanist);
    font-size: 28px;
    font-weight: 800;
    line-height: 120%;
    text-transform: none;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin: 0;

    > div {
      padding: 0;
      width: calc(50% - 12px);
    }
  }

  .inputsBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .btn,
  button {
    width: 100%;
  }

  :global(.ant-form-item-has-error) {
    input,
    textarea {
      border: 2px solid var(--errorColor) !important;
    }
  }

  @media (max-width: 800px) {
    padding: 16px;
    text-align: center;
    gap: 24px;
    text-align: left;
    h3 {
      font-size: 22px;
    }

    .titleBlock p {
      font-size: 16px;
      line-height: 24px;
    }

    .row {
      flex-direction: column;
      gap: 16px;
      > div {
        width: 100%;
      }
    }

    .inputsBlock {
      gap: 16px;
    }
  }

  .error {
    color: var(--errorColor);
    text-align: center;
    margin-top: 4px;
  }
}

.successBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: var(--gray200);

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  .icon {
    color: var(--coral);
    padding: 10px;
    border-radius: 50%;
    background: #fff;
    font-size: 24px;
  }

  h3 {
    margin: 8px 0 16px;
  }

  @media (max-width: 800px) {
    height: 600px;

    h3 {
      margin: 8px 0;
    }
  }
}

.formItem {
  position: relative;
  margin: 0;

  :global(.ant-form-item-required)::before {
    content: none !important;
  }

  :global(.ant-form-item-row) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  :global(.ant-col) {
    flex: 1;
  }

  label {
    text-align: left;
    width: 100%;
    height: fit-content !important;
  }

  .label {
    color: inherit;
  }

  :global(.ant-input-outlined) {
    border-radius: 0;
    border: 1px solid var(--dark60);
  }

  .input {
    background: #fff;
    min-height: 44px;
    padding: 9px 12px;
    display: block;

    input {
      color: var(--dark100);
      border: none;

      &::placeholder {
        color: var(--dark60);
      }

      &:is(
          :-webkit-autofill,
          :autofill,
          :-internal-autofill-selected,
          :-internal-autofill-previewed
        ) {
        -webkit-text-fill-color: var(--dark100);
        color: var(--dark100);
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        background-color: #fff !important;
        border: none;
        transition: box-shadow 10000s, background 10000s;
      }
    }
  }

  .textarea {
    height: calc(96px - 12px * 2);
    background: #fff;
    min-height: 96px;

    textarea {
      padding: 12px;
      color: var(--dark100);

      &::placeholder {
        color: var(--dark60);
      }
    }
  }

  .count {
    position: absolute;
    right: 0;
    top: calc(72px + 20px + 4px);
  }
}
