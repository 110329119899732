.wrapper {
  min-height: 100vh;
  background: var(--dark100);
  padding-bottom: 80px;

  h2,
  p {
    color: #fff;
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
  }

  h2 {
    font-family: var(--urbanist);
    font-size: 48px;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: 1.44px;
    text-transform: none;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .container {
    > p {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  > :global(.image-cont) {
    width: 100%;
    height: auto;
    aspect-ratio: 1600/210;
  }

  .btn {
    margin: 0 auto;
  }

  @media (max-width: 1440px) {
    h2 {
      font-size: 32px;
      line-height: 120%;
    }
  }

  @media (max-width: 768px) {
    padding: 0 16px 64px;

    > :global(.image-cont) {
      width: 100%;
      height: auto;
      aspect-ratio: 717/280;
    }

    .container {
      padding: 0;

      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 900;
      line-height: 120%;
      letter-spacing: 0.96px;
    }

    .btn {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  padding-bottom: 54px;
  margin-top: 64px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 40px;
    padding-bottom: 48px;
  }
}

.gridItem {
  background: var(--gray100);
  padding: 24px;
  .icon {
    color: var(--coral);
    font-size: 32px;
  }

  p {
    text-align: left;
  }

  p:first-of-type {
    font-family: var(--merchant);
    color: var(--dark100);
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  p:last-of-type {
    font-family: var(--avenir);
    color: #242423;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    b {
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    padding: 32px 24px;

    p:first-of-type {
      margin: 4px 0 16px 0;
    }
  }
}
