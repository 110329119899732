.wrapper {
  padding: 40px;
  background: #f4f7fb;
  margin: 24px;

  @media (max-width: 800px) {
    padding: 12px;
    margin: 16px;
  }
}

.title {
  color: var(--sky200);
  font-family: var(--argent);
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;

  @media (max-width: 800px) {
    font-size: 22px;
    line-height: 24px;
  }
}

.inline {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 32px;
  img,
  p {
    display: inline;
    font-family: var(--avenir);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;

    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  img {
    @media (max-width: 800px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 4px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 320px);
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid rgba(0, 4, 48, 0.16);
  i {
    font-size: 24px;
  }

  &:nth-child(2n - 1) {
    background: #fff;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    font-family: var(--avenir);

    &:first-of-type {
      flex-grow: 1;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      text-align: center;
      flex-basis: 112px;
      flex-shrink: 0;
    }

    &:nth-of-type(3) {
      font-weight: 700;
    }
  }

  @media (max-width: 800px) {
    gap: 0;
    align-items: flex-start;

    &:last-of-type {
      border: none;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      &:nth-of-type(2),
      &:nth-of-type(3) {
        flex-shrink: 0;
        flex-basis: 64px;
      }
      &:nth-of-type(3) {
        flex-basis: 56px;
      }
    }

    i {
      display: none;
    }
  }
}

.headRow {
  color: var(--sky200);
  background: none !important;
  p {
    &:first-of-type {
      font-weight: 700;
    }
  }
}
