.wrapper {
  --descriptionMarginTopBottom: spx(4) 0 spx(13) 0;
  --titleMarginBottom: spx(13);
  --factTitleMarginBottom: spx(3);

  .sticky {
    min-height: 100vh;
  }

  .grid {
    position: relative;
    top: 0;
    display: grid;
    grid-template-columns: auto 668px;
    align-items: center;
    background: var(--gray200);
    padding-top: 64px;
    padding-bottom: 64px;
    min-height: 100vh;
    gap: 24px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
      height: fit-content;
      padding: 24px 16px;
      gap: 40px;
    }
  }

  .title {
    font-family: var(--merchant);
    font-size: 32px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: left;

    @media (max-width: 1000px) {
      text-align: center;
      font-size: 28px;
    }

    b {
      display: block;
      font-family: var(--urbanist);
      font-weight: 900;
      letter-spacing: 0.96px;
      text-transform: none;
    }
  }

  .description {
    margin-top: 16px;
    font-family: var(--urbanist);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 20px;
    }

    span {
      display: block;
    }
  }

  .primary {
    margin-top: 52px;
    @media (max-width: 1000px) {
      margin: 16px auto 0;
    }
  }

  .listsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
      height: fit-content;
    }
  }

  .gridContentWrapper {
    position: relative;
    height: 100%;

    @media (max-width: 1000px) {
      height: fit-content;
    }
  }

  .gridContent {
    position: absolute;
    width: 100%;
    top: 50vh;
    transform: translateY(-50%);

    // position: fixed;
    width: calc(min(1360px, 100vw) - 668px - 24px - 48px * 2);

    @media (max-width: 1000px) {
      position: relative !important;
      top: unset !important;
      transform: unset !important;
      width: auto !important;
      height: fit-content !important;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    transform: translateY(0);
    transition: transform 0.2s;
    &:first-of-type {
      margin-top: 54px;

      @media (max-width: 1000px) {
        margin: 0;
      }
    }

    @media (max-width: 1000px) {
      transform: unset;
    }
  }

  .listItem {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    background: #e3e3e3;
    align-items: center;
    width: 100%;
    height: fit-content;

    span {
      width: 100%;
    }

    .listItemTitle {
      font-family: var(--merchant);
      font-size: 28px;
      line-height: 120%;
      text-transform: uppercase;
      padding: 0 10px;
    }

    .listItemDescription {
      color: rgba(31, 31, 31, 0.6);
      font-family: var(--urbanist);
      font-size: 14px;
      line-height: 20px;
    }

    &.color-blue {
      background: #b7d5e0;
    }
    &.color-beige {
      background: #e9e4dd;
    }
    &.color-black {
      background: var(--dark100);
      .listItemTitle {
        color: #fff;
      }

      .listItemDescription {
        color: #e3e3e3;
      }
    }
    &.color-white {
      background: #fff;
    }
  }

  .endnotes {
    padding: 64px 120px;
    .endnotesTitle {
      font-family: var(--urbanist);
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
    .endnotesDescription {
      font-family: var(--urbanist);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 16px;
    }

    @media (max-width: 800px) {
      padding: 40px 16px !important;

      .endnotesTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .endnotesDescription {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
