.wrapper {
  background: var(--gray100);
  margin-top: -64px;

  .row {
    display: flex;
    flex-wrap: nowrap;
    gap: 48px;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      :global(.image-cont) {
        height: 300px;
      }
    }
  }

  :global(.image-cont) {
    position: relative;
    width: 100%;
    padding-top: 50%;
  }

  @media (max-width: 800px) {
    margin-top: unset;
  }
}

.col {
  padding-top: 128px;
  padding-bottom: 128px;
  padding-right: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: max(48px, calc((100vw - 1360px) / 2)) !important;
  justify-content: center;

  h2 b {
    text-transform: none;
    font-weight: 900;
    font-family: var(--urbanist);
  }

  p {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    padding: 0;
    padding-right: 16px !important;
    padding-bottom: 32px;
    padding-left: 16px !important;

    h2 {
      text-align: center;
      letter-spacing: unset;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.statisticsItem {
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    line-height: 24px;
    max-width: 150px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
    }
  }

  .icon {
    color: var(--coral);
    font-size: 24px;
  }
}

.statisticsItems {
  display: grid;
  row-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: max-content auto;
    justify-content: space-between;
  }
}

.descriptions {
  display: flex;
  flex-direction: column;
  gap: 1ch;
}
