.button {
  height: 52px;
  padding: 12px 16px;
  border-radius: 8px;
  background: #000430;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  font-family: var(--avenir);
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  min-width: 247px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    opacity: 0.75;
    & .icon {
      transform: translateX(4px);
    }
  }

  &:active {
    opacity: 0.5;
  }

  & :global(.icon) {
    transition: transform var(--trTime) ease-out;
    font-size: 20px;
    &::before {
      color: #fff;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 44px;
  }
}
