.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 700px;
  width: 100%;

  @media (max-width: 613px) {
    height: auto;
    padding-top: 16px;
    justify-content: flex-start;
    gap: 32px;
  }
}

.title {
  font-family: var(--urbanist);
  font-size: 22px;
  font-weight: 800;
  line-height: 120%;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (max-width: 613px) {
    height: auto;
    padding-top: 16px;
  }
}

.piesWrapper {
  display: flex;
  gap: 40px;
  align-items: flex-start;
  width: 100%;
  max-width: 826px;

  @media (max-width: 613px) {
    gap: 16px;
  }
}

.pieWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  align-items: center;
}

.pieTitle,
.description {
  font-family: var(--urbanist);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 8px;
  max-width: 700px;
}

.pieTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
  height: 38px;

  @media (max-width: 613px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    height: auto;
  }
}

.percentage {
  color: #67b5d2;
  text-align: center;
  font-family: var(--urbanist);
  font-size: 24px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.72px;

  &.secondary {
    color: #cd7764;
  }

  @media (max-width: 613px) {
    font-size: 16px;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.48px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 170px;

  .primary {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

.shareButton {
  display: flex;
  gap: 4px;
  font-family: var(--urbanist);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  align-items: center;
  cursor: pointer;
}

.switchWrapper {
  display: flex;
  align-items: center;
  font-family: var(--urbanist);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  gap: 8px;
  margin-top: -8px;
}

.switchWrapperContent {
  position: relative;
  height: 25.6px;
}

.switchInput {
  width: 44px;
  height: 25.6px;
  border-radius: 160px;
  background: var(--gray100);
  transition: background 0.25s;

  & + .switch {
    position: absolute;
    border-radius: 160px;
    width: 19px;
    height: 19px;
    background: #fff;
    box-shadow: 0px 3.2px 3.2px rgba(68, 83, 113, 0.2),
      0px 0px 1.6px rgba(68, 83, 113, 0.1);
    left: 3.2px;
    top: 3.2px;
    transition: left 0.25s;
  }

  &:checked {
    background: var(--coral);
  }

  &:checked + .switch {
    left: 22.4px;
  }
}
