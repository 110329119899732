.wrapper {
  flex-basis: 513px;
  flex-shrink: 0;
  position: sticky;
  top: calc(var(--headerHeight) + 64px);
  align-self: start;
  padding-bottom: 128px;
}

.blogItemImage img {
  aspect-ratio: 513/276;
  height: auto !important;
  position: relative !important;
}

.navigationWrapper {
  margin-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.navigationItem {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: var(--dark60);
  font-family: var(--urbanist);
  letter-spacing: normal;
  text-transform: none;
  cursor: pointer;
  transition: color 0.05s ease;

  &.active {
    color: var(--dark100);
  }
}

@media (max-width: 1100px) {
  .wrapper {
    flex-basis: 300px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 0;
    top: unset;
    bottom: unset;
    margin-bottom: 24px;
  }

  .blogItemImage {
    display: none;
  }
}
