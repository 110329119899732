.wrapper {
  background-image: conic-gradient(
    var(--color) var(--to),
    var(--colorBg) calc(var(--to) + 0.1%)
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--width);
  height: var(--width);
  max-width: var(--width);
  max-height: var(--width);
  min-width: var(--width);
  min-height: var(--width);

  > div {
    content: '';
    background: var(--colorCenter);
    width: var(--widthCenter);
    height: var(--widthCenter);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
