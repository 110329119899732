.wrapper {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  transform: translateY(0);
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--headerHeight));
  background-color: #fff;
  z-index: 3;
  padding: 64px 0 128px;
  overflow: hidden;

  p {
    max-width: 1130px;
    margin-top: 12px;
    margin-bottom: 36px;
    font-size: 20px;
    font-family: var(--merchant);
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0.6px;
  }

  @media (max-width: 768px) {
    padding: 70px 16px 0;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;

    h1 {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 200;
      letter-spacing: 1.44px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: var(--urbanist);
      letter-spacing: unset;
      margin-bottom: 0;
    }
  }
}

.images {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto 0;
  padding: 60px 0;
  height: 100%;
  max-height: 420px;

  @media (max-width: 768px) {
    order: -1;
    min-height: 234px;
    margin-bottom: 24px;
  }

  @media (max-height: 768px) {
    padding: 32px 0;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin: 0 0 24px 0;
    height: fit-content;
  }
}

.clouds {
  overflow: hidden;
  width: 100%;
  position: relative;
  background: #f2f2f2;
  height: 100%;
}

.image {
  position: absolute;
  background-blend-mode: multiply;

  &.family,
  &.familyColorful {
    top: 0;
    height: 100%;
    width: auto;
    aspect-ratio: 560/620;
  }

  &.family {
    background: url('/images/legend/family.webp') #f2f2f2 no-repeat;
    background-size: 100%;
  }

  &.familyColorful {
    background: url('/images/legend/family_colorful.webp') #f2f2f2 no-repeat;
    opacity: 0;
    transition: opacity 1.8s;
    background-size: 100%;
    &.opacity {
      opacity: 1;
    }
  }

  &.cloudsLeft {
    left: 0;
    width: 633.182px;
    height: 700px;
    transform: translateY(-125px) translateX(-150px) rotate(-4.126deg);
    background: url('/images/legend/clouds_left.webp') #f2f2f2 no-repeat;
    background-size: auto 600px;
    transition: transform 1.3s ease-out;

    &.moveClouds {
      transform: translateY(-125px) translateX(-400px) rotate(-4.126deg);
    }
  }

  &.cloudsRight {
    right: 0;
    width: 703px;
    height: 700px;
    transform: scale(-1, 1) translateX(-150px) translateY(-125px)
      rotate(-10.191deg);
    background: url('/images/legend/clouds_right.webp') #f2f2f2 no-repeat;
    background-size: auto 600px;
    transition: transform 1.3s ease-out;

    &.moveClouds {
      transform: scale(-1, 1) translateX(-400px) translateY(-125px)
        rotate(-10.191deg);
    }
  }

  @media (max-width: 768px) {
    &.family,
    &.familyColorful {
      width: 234px;
      height: 234px;
      background-size: contain;
    }

    &.cloudsLeft,
    &.cloudsRight {
      background-size: contain;
      background-color: #fff;
      z-index: -1;
      width: 320px;
      height: 300px;
    }

    &.cloudsLeft {
      transform: scale(1.2) translateY(370px) translateX(-140px);
    }

    &.cloudsRight {
      transform: scale(-1, 1) translateX(-35%) translateY(-200px);
    }
  }
}
